.tabValue {
  cursor: pointer;
  margin-right: 4rem;
}
.tabWrapper {
  border-bottom: none !important;
  width: max-content;
}

.tabActive {
  border-bottom: 4px solid #ebe645;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
