#resident__profile.profile {
  .profile {
    height: 100%;

    &__row {
      width: 100%;
      height: 100%;
      margin: 0 -7.5px;

      @include respond(large) {
        display: grid;
        grid-template-columns: 33% 67%;
      }
    }

    &__col {
      flex: 0 0 auto;
      max-width: 100%;
      height: 100%;
      padding: 0 7.5px;
      overflow-x: hidden;

      &--left {
        h5 {
          padding-bottom: 3rem;

          @include respond(large) {
            padding-bottom: 0;
          }
        }
      }

      &--right {
        @include respond(large) {
          height: calc(100vh - 195px);
          overflow-y: auto;
        }
      }
    }

    &__sidebar {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      margin-bottom: 2rem;
      overflow-x: auto;

      @include respond(large) {
        display: block;
        margin-bottom: 0;
        overflow-x: none;
      }

      button {
        min-width: fit-content;
        white-space: nowrap;
      }
    }

    &__vehicle {
      &__button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
      }
    }
  }
}
