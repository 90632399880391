[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  // speak: never;
}

.icon-filter::before {
  content: '\e923';
}
.icon-funnel::before {
  content: '\e924';
}
.icon-data::before {
  content: '\e922';
}
.icon-cart::before {
  content: '\e921';
}
.icon-activity::before {
  content: '\e900';
}
.icon-add-circle::before {
  content: '\e901';
}
.icon-angle-left::before {
  content: '\e902';
}
.icon-angle-right::before {
  content: '\e903';
}
.icon-arrow-down::before {
  content: '\e904';
}
.icon-card-receive::before {
  content: '\e905';
}
.icon-chart::before {
  content: '\e906';
}
.icon-close::before {
  content: '\e907';
}
.icon-dashboard::before {
  content: '\e908';
}
.icon-delete-alt::before {
  content: '\e909';
}
.icon-delete::before {
  content: '\e90a';
}
.icon-diagram::before {
  content: '\e90b';
}
.icon-document-text::before {
  content: '\e90c';
}
.icon-edit::before {
  content: '\e90d';
}
.icon-ellipsis::before {
  content: '\e90e';
}
.icon-empty-wallet::before {
  content: '\e90f';
}
.icon-envelope-badged::before {
  content: '\e910';
}
.icon-estate-info::before {
  content: '\e911';
}
.icon-export::before {
  content: '\e912';
}
.icon-eye-line::before {
  content: '\e913';
}
.icon-house::before {
  content: '\e914';
}
.icon-logout::before {
  content: '\e915';
}
.icon-menu::before {
  content: '\e916';
}
.icon-message-question::before {
  content: '\e917';
}
.icon-message-text::before {
  content: '\e918';
}
.icon-personal-card::before {
  content: '\e919';
}
.icon-receipt-edit::before {
  content: '\e91a';
}
.icon-receipt::before {
  content: '\e91b';
}
.icon-search::before {
  content: '\e91c';
}
.icon-speaker::before {
  content: '\e91d';
}
.icon-upload::before {
  content: '\e91e';
}
.icon-user-square::before {
  content: '\e91f';
}
.icon-wallet::before {
  content: '\e920';
}
