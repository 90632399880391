.custom-file-upload {
  display: inline-block;
  margin-top: 2rem;
  padding: 12px;
  border-radius: 8px;
  font-size: 1.5rem;
  cursor: pointer;

  @include themed() {
    background-color: set-theme(secondary-color);
  }

  &:hover {
    color: color(white);

    @include themed() {
      background-color: set-theme(primary-color);
    }
  }
}

.user_profile {
  .profile_img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
}
