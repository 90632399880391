html {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 62.5% /* 10px */;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  @include font-weight(regular);
  box-sizing: border-box;
  overflow: hidden;
  color: color(grey);
  letter-spacing: normal;
  line-height: normal;
}

#root {
  width: 100vw;
  height: 100vh;
  // overflow: auto;

  @include themed() {
    font-family: set-theme(body-font-family);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
a {
  cursor: pointer;
}

button {
  &:focus {
    @include box-shadow(none);
    outline: none;
  }
}

a,
span[role='link'] {
  display: inline-block;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.logout_link {
  cursor: pointer;
}
