.upload_container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 27px 0px;
  margin-bottom: 3rem;

  .file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .file-input-cover {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .file-input-cover-icon {
    height: 34;
    width: 29;
  }
  .file-input-cover-text {
    color: #1d2733;
    font-size: 14px;
  }
  .file-input-cover-caption {
    color: #0050c8;
    font-size: 12px;
    text-decoration: none;
    z-index: 100;
  }
}
.prev_image {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 2rem;
}

.icon-del {
  font-size: 2rem;

  @include themed() {
    color: set-theme(secondary-color) !important;
  }
}

.view_modal_image {
  width: 250px;
  height: 250px;
  margin: 3rem auto;
  object-fit: contain;
  transition-delay: 1s;
}

.view_modal_image:hover {
  transform: scale(1.4);
  transition: all 0.1s ease-in-out;
}

.display_icon {
  font-size: 2rem;
  cursor: pointer;
  padding-top: 3rem;
  padding-left: 1rem;
}
.display_form {
  margin-bottom: -3.5rem !important;
}
