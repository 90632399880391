.sidebar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 260px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 300ms;

  @include themed() {
    background-color: set-theme(primary-color);
  }

  .activeClass {
    @include themed() {
      background-color: set-theme(secondary-color);
    }
    display: flex;
    align-items: center;
    padding: 2rem 2.5rem;
    transition: all 0.3s ease;
    color: color(black);
  }

  &__icon {
    width: 3.6rem;
  }

  &-inner {
    width: 100%;
    height: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 110px;

    &--small {
      justify-content: flex-end;
    }

    .small-logo {
      display: none;
      width: 65px;
      padding: 10px;
    }

    img {
      display: block;
      width: 124px;
    }
  }

  &__link {
    @include font-size(small);
    @include transition(all 0.3s ease);
    display: flex;
    align-items: center;
    padding: 2rem 2.5rem;
    color: color(white);

    &:hover {
      text-decoration: none;

      @include themed() {
        background-color: set-theme(secondary-color);
        color: set-theme(primary-color);
      }
    }
  }

  nav {
    width: 100%;
    padding-bottom: 100px;
  }

  .treeview {
    position: relative;
    cursor: pointer;

    &-menu {
      display: none;
      width: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: color(white);
      list-style: none;

      @include themed() {
        background-color: darken(
          $color: set-theme(primary-color),
          $amount: 10%
        );
      }

      &.active {
        display: block;
        animation: dropdownAnimateIn 0.3s;
      }

      &.animating {
        transition: width 0.3s ease-in-out;
        transition-property: width, margin-top, margin-bottom;
      }

      .sidebar__link {
        padding-left: 6.1rem;
      }
    }
  }
}

.noPermission {
  display: none !important;
}
