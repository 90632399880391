.modal_nav {
  cursor: pointer;
  margin-right: 3rem;

  @include themed() {
    color: set-theme(primary-color);
  }
}

.modal_vehicle_label {
  margin-bottom: 2rem;

  @include themed() {
    color: set-theme(primary-color);
  }
}
.modal_tabs {
  border-bottom: 0 !important;
  width: 75%;
  margin: auto;
  justify-content: center;
  .modal_tab {
    border: 0 !important;

    @include themed() {
      color: set-theme(primary-color);
    }
  }
  .modal_tab.active {
    @include themed() {
      border-bottom: 2px solid set-theme(secondary-color) !important;
      color: set-theme(primary-color) !important;
    }
  }
}
