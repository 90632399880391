#dashboard .dashboard {
  &__card {
    box-sizing: border-box;
    @include respond(small) {
      padding: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background: color(white);
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
      margin-bottom: 15px;
    }

    @include respond(large) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    .card__item {
      @include respond(x-small) {
        padding: 3rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background: color(white);
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
        margin-bottom: 15px;
      }
      @include respond(small) {
        padding: 0;
        border: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
        margin-bottom: 0;
      }
    }
    .card__item_group {
      @include respond(x-small) {
        display: flex;
      }
      @include respond(small) {
        display: block;
      }
    }

    .card__arrow {
      width: 94px;
      height: 2px;
      margin-top: 50px;
      transform: rotate(90deg);

      @include themed() {
        background: set-theme(secondary-color);
      }
    }

    .card__line {
      margin: 2rem 0;

      @include themed() {
        background: set-theme(secondary-color);
      }
      @include respond(x-small) {
        display: none;
      }
      @include respond(small) {
        display: block;
      }
    }

    .card__icon {
      @include font-size(regular);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-bottom: 1.5rem;
      border-radius: 100%;
      color: color(white);
      text-align: center;
      @include respond(x-small) {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      @include respond(small) {
        width: 32px;
        height: 32px;
        margin-right: 0;
      }
    }
  }

  &__order-item {
    display: block;
  }

  table {
    td ul li {
      &:not(:last-child) {
        .dashboard__order-item {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

#dashboard-metric .dashboard {
  &__card {
    box-sizing: border-box;
    @include respond(small) {
      padding: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background: #e8effd;
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
      margin-bottom: 15px;
    }

    @include respond(large) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
    .card__item {
      .wallet-item {
        color: #000957;
        font-size: 30px;
        .rounded-circle {
          display: flex;
          justify-content: center;
          justify-items: center;
          padding: 10px;
          width: 64px;
          height: 64px;
          background: #ebe645;
          border-radius: 100%;
        }
      }
      @include respond(x-small) {
        padding: 3rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background: color(white);
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
        margin-bottom: 15px;
      }
      @include respond(small) {
        padding: 0;
        border: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
        margin-bottom: 0;
      }
    }
    .card__item_group {
      @include respond(x-small) {
        display: flex;
      }
      @include respond(small) {
        display: block;
      }
    }

    .card__arrow {
      width: 94px;
      height: 2px;
      margin-top: 50px;
      transform: rotate(90deg);

      @include themed() {
        background: set-theme(secondary-color);
      }
    }

    .card__line {
      margin: 2rem 0;

      @include themed() {
        background: set-theme(secondary-color);
      }
      @include respond(x-small) {
        display: none;
      }
      @include respond(small) {
        display: block;
      }
    }

    .card__icon {
      @include font-size(regular);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-bottom: 1.5rem;
      border-radius: 100%;
      color: color(white);
      text-align: center;
      @include respond(x-small) {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      @include respond(small) {
        width: 32px;
        height: 32px;
        margin-right: 0;
      }
    }
  }

  &__order-item {
    display: block;
  }

  table {
    td ul li {
      &:not(:last-child) {
        .dashboard__order-item {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
