.bill-card {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  border: 1px solid rgba(28, 31, 49, 0.1);
  border-radius: 7px;
  background-color: color(white);

  @include respond(small) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__button {
    margin-top: 1rem;
    margin-left: 2rem;

    @include respond(small) {
      margin-top: 0;
      margin-left: 1rem;
    }

    &--add {
      @include font-size(x-small);
      padding-top: 0.4rem;
      padding-right: 0.8rem;
      padding-bottom: 0.4rem;
      padding-left: 1rem;
      border-radius: 5px;

      @include respond(small) {
        @include font-size(small);
        padding-top: 0.8rem;
        padding-right: 1.1rem;
        padding-bottom: 0.8rem;
        padding-left: 1.5rem;
        border-radius: 10px;
      }

      span {
        margin-left: 1.3rem;

        @include themed() {
          color: set-theme(secondary-color);
        }
      }
    }

    &--alt {
      @include font-size(medium);
      padding: 1rem;
      transition: all 0.3s ease;
      border: 0 !important;
      background-color: lighten($color: #ebe645, $amount: 35%) !important;

      @include themed() {
        color: set-theme(primary-color) !important;
      }

      &:hover {
        background-color: color(white) !important;
      }
    }
  }

  &__form {
    position: relative;

    button {
      position: absolute;
      top: 50%;
      left: 180px;
      height: 50px;
      transform: translateY(-40%);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .form__group {
      width: 100%;
      max-width: 200px;
    }

    .form__control {
      @include font-size(small);
      // min-height: auto;
      padding: 0.3rem 1.2rem;
      border-radius: 0.6rem;

      @include respond(small) {
        @include font-size(regular);
        min-height: 48px;
        padding: 1.2rem 1.6rem;
        border-radius: 1rem;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__icon {
    display: flex;
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @include respond(small) {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
    }

    @include themed() {
      background-color: rgba($color: set-theme(secondary-color), $alpha: 0.5);
    }

    svg {
      width: 20px;

      @include respond(small) {
        width: 34px;
      }
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: row;
    // align-items: flex-start;

    @include respond(small) {
      flex-direction: row;
    }
  }

  &__textbox {
    flex: 1;
    padding: 0 2rem;
  }

  &__title {
    @include font-size(small);

    @include respond(small) {
      @include font-size(regular);
    }
  }

  &__amount {
    @include font-size(small);
    @include font-weight(medium);
    margin-bottom: 0;

    @include respond(small) {
      @include font-size(medium);
      margin-bottom: 0.5rem;
    }
  }

  &__qty {
    display: inline-flex;
    align-items: center;

    button {
      @include font-size(regular);
      transition: all 0.3s;

      @include themed() {
        border: 1px solid rgba($color: set-theme(secondary-color), $alpha: 0.6);
        background-color: rgba($color: set-theme(secondary-color), $alpha: 0.6);
      }

      &:disabled {
        opacity: 0.7;
      }

      &:not(:disabled) {
        &:hover {
          @include themed() {
            border-color: rgba($color: set-theme(primary-color), $alpha: 0.2);
            background-color: rgba(
              $color: set-theme(primary-color),
              $alpha: 0.2
            );
          }
        }
      }
    }

    p {
      margin: 0 1rem;
    }
  }

  &-wrapper {
    flex: 0 0 100%;
    align-items: stretch;
    width: 100%;
    padding: 0 1.5rem 3rem;

    @include respond(medium) {
      flex: 0 0 calc(100% / 2);
      width: calc(100% / 2);
    }

    @include respond(large) {
      flex: 0 0 100%;
      width: 100%;
    }

    @include respond(maximum) {
      flex: 0 0 calc(100% / 3);
      width: calc(100% / 3);
    }
  }
}
