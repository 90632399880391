#admin__report {
  .report {
    &__form {
      .col {
        flex: 0 0 100%;

        @include respond(medium) {
          flex: 0 0 50%;
        }

        @include respond(largest) {
          flex: 1 0;
        }
      }

      .form__group {
        margin-bottom: 1.5rem;

        @include respond(medium) {
          margin-bottom: 4rem;
        }
      }
    }
  }
}
