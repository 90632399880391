@keyframes dropdownAnimation {
  from {
    opacity: 0;
    @include transform(translate3d(0, 70px, 0));
  }

  to {
    opacity: 1;
    @include transform(translate3d(0, 42px, 0));
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes dropdownAnimateOut {
  0% {
    display: block;
    width: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  100% {
    display: block;
    width: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@keyframes dropdownAnimateIn {
  0% {
    width: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  100% {
    width: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
