h1,
h2,
h3 {
  @include font-size(m-large);
  @include font-weight(bold);

  margin-bottom: 1.5rem;
  color: color(dark);

  @include themed() {
    font-family: set-theme(heading-font-family);
  }

  @include respond(maximum) {
    @include font-size(x-large);
  }
}

h4,
h5 {
  @include font-size(medium);
  margin-bottom: 1.5rem;
  color: color(dark);

  @include themed() {
    font-family: set-theme(sub-heading-font-family);
    font-weight: set-theme(sub-heading-font-weight);
  }

  @include respond(maximum) {
    @include font-size(large);
  }
}

h6 {
  @include font-size(medium);
  margin-bottom: 1.5rem;
  color: color(dark);

  @include themed() {
    font-family: set-theme(sub-heading-font-family);
  }
}

p,
li,
a {
  @include font-weight(regular);
  @include font-size(regular);

  letter-spacing: 0.2px;
  line-height: 1.6;

  @include themed() {
    font-family: set-theme(body-font-family);
  }
}

p {
  margin-bottom: 0.5rem;
}

ul {
  list-style: none;
}
