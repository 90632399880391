.chart_box {
  height: 350px;
  overflow-y: auto;
}

.chat_card {
  .card_msg_text {
    padding-left: 1rem;
  }
  .card_img_text {
    display: grid;
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    border-radius: 100%;
    place-items: center;
  }
  .card_img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  .chat_downlaod {
    margin-left: 1rem;
    border: 1px solid rgba(41, 45, 50, 0.9);
    border-radius: 4.41928px;
    background: #e8effd;
  }
  .download_text {
    cursor: pointer;

    @include themed() {
      color: set-theme(secondary-color);
    }
  }
  .file_icon {
    margin-top: 0.3rem;
    margin-right: 0.5rem;
  }
}
.user_img {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  border-radius: 100%;
}

.blue-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.file_select {
  opacity: 0;
}

.complaint_style_box {
  position: relative;
  height: 80vh;
  overflow-y: auto;
  .complaint_container {
    @include respond(x-small) {
      width: 100% !important;
    }
    @include respond(small) {
      width: 75% !important;
    }
  }
}

.upload_wrapper {
  position: relative;
  height: 47px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  text-align: center;
}
