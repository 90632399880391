#notification {
  .accordion-button {
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: 450;
    font-size: 18.6064px;
    line-height: 24px;
    padding: 2rem;
    border: 1px solid #ebe645;
    border-radius: 5px;

    .header-bell {
      border: 1px solid #ebe645;
      border-radius: 5px;
      padding: 5px;
      margin-right: 1.5rem;
    }
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    color: #141418;
    background-color: #ebe645 !important;
    border-radius: 1.5rem;
    // border-top-right-radius: 1.5rem;
  }

  .accordion-item {
    border: none;
    // background: #ebe645;
  }

  .accordion-body {
    // background-color: #ebe645 !important;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 300;
    font-size: 18.6064px;
    line-height: 26px;
    // color: #ffffff;

    .Accordion-body-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .accordion-document {
      background: #e8effd;
      border-radius: 7.51368px;
      padding: 1rem;
      margin-right: 2rem;
      margin-top: 1rem;
    }
    .accordion-document-download {
      background: #577bc1;
      padding: 0.7rem;
      font-style: normal;
      font-weight: 300;
      font-size: 12.0219px;
      line-height: 17px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
