.badge {
  @include font-size(x-small);
  @include font-weight(regular);
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  text-align: center;

  &--outline {
    border-width: 1px;
    border-style: solid;
    background-color: transparent;

    &-primary {
      @include themed() {
        border: 1px solid set-theme(primary-color);
        color: set-theme(primary-color);
      }
    }

    &-secondary {
      @include themed() {
        border: 1px solid set-theme(secondary-color);
        color: set-theme(secondary-color);
      }
    }
  }

  &--active {
    border-color: color(green);
    color: color(dark);
  }

  &--inactive {
    border-color: color(red);
    color: color(dark);
  }

  &--primary {
    color: color(white);

    @include themed() {
      background-color: set-theme(primary-color);
    }
  }

  &--secondary {
    color: color(black);

    @include themed() {
      background-color: set-theme(secondary-color);
    }
  }
}

@each $name, $color in $badge-colors {
  .badge--#{'' + $name} {
    background-color: $color;
    color: color(white);
  }

  .badge--outline-#{'' + $name} {
    border: 1px solid $color;
    color: $color;
  }
}
