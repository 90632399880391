.payment-method {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;

  img {
    height: 60px;
    margin-left: 1.5rem;
    padding: 1rem;
  }

  h5 {
    @include font-size(m-large);
    @include font-weight(bold);
    margin-bottom: 0;
    padding: 1rem 2rem;
    color: color(black);
  }
}
