.faq {
  &__item {
    margin-bottom: 2rem;
    border-radius: 13px !important;
    border: 1px solid rgba(28, 31, 49, 0.1) !important;
    background: #e9ebfa;

    &--active {
      background: #ebe645;
    }

    .accordion-header button {
      @include font-size(regular);
      @include font-weight(medium);
      line-height: 1.4;

      @include themed() {
        font-family: set-theme(body-font-family);
      }

      background-color: transparent;
      padding: 3rem 2rem;
      border-top-left-radius: 13px !important;
      border-top-right-radius: 13px !important;
      border: 0 !important;
      box-shadow: none;
      background: transparent;

      @include respond(large) {
        @include font-size(medium);
      }
    }

    .accordion-body {
      padding: 3rem 2rem;
      border-bottom-left-radius: 13px !important;
      border-bottom-right-radius: 13px !important;
      background: transparent;
    }

    .accordion-button:not(.collapsed) {
      color: color(dark);
    }
  }

  &__inner {
    background: rgba(0, 9, 87, 0.3);
    padding: 2rem 2rem 4rem;
    border-radius: 13px !important;
    white-space: pre-wrap;

    p {
      color: color(white);
      margin-bottom: 0;
    }
  }
}
