.filter {
  display: inline-block;
  position: relative;
  z-index: 50;
  cursor: pointer;

  &__link {
    @include font-size(medium);
    @include font-weight(medium);
    display: inline-flex;
    align-items: flex-end;
  }

  &-menu {
    @include context-menu;
    min-width: 300px;
    margin-left: -220px;
    padding: 2rem 1rem;
    border-radius: 7px;

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-top: 2rem !important;

      .btn {
        padding: 0.6rem 1.8rem;
      }
    }

    &__item {
      padding: 1rem 1rem 0;

      &:last-child {
        padding-bottom: 0;
      }

      .form__group {
        margin-bottom: 0;
      }

      &__listitem {
        border-bottom: 1px solid rgba(128, 128, 128, 0.191);
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
          background-color: rgba(128, 128, 128, 0.243);
        }
      }
    }
  }
}
