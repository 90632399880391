#resident__poll {
  .poll {
    padding: 1.5rem;
    border: 1px solid #dee2e6;
    border-radius: 5px;

    &--no-border {
      margin-bottom: 3rem;
      padding: 0;
      border: none;
      border-radius: 0;
    }

    &-checkbox {
      justify-content: space-between !important;
      margin: 0 !important;
      padding: 1.5rem;
      transition: background-color 0.3s ease;
      border: 1px solid #dee2e6;

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:not(:last-child) {
        border-bottom: 0;
      }

      &:hover {
        background-color: color(light-blue);

        .checkmark--radio {
          border-color: color(black);

          &::after {
            background: color(black);
          }
        }
      }

      &--voted {
        cursor: no-drop;
      }

      .form__checkbox {
        .checkmark {
          transform: translateY(-50%);
        }

        &-group {
          width: 16px;
          height: 16px;
          margin-bottom: 0 !important;
        }
      }

      .poll-label {
        margin-bottom: 0;

        @include respond(medium) {
          @include font-size(regular);
        }
      }
    }

    &-col {
      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(3) {
        width: 50px;
        text-align: right;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: stretch;
      margin: 1.5rem 0;
    }
  }

  .progress {
    height: 1rem;
    border-radius: 1rem;

    &-bar {
      border-radius: 1rem;
    }
  }

  @for $i from 1 through length($progess-colors) {
    .poll-row:nth-child(#{length($progess-colors)}n + #{$i}) {
      .progress-bar {
        background: nth($progess-colors, $i);
      }
    }
  }
}
