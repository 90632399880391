.form {
  &__control {
    @include font-size(small);
    min-height: 48px;
    padding: 1.2rem 1.6rem;
    border-radius: 10px;

    @include respond(medium) {
      @include font-size(regular);
    }

    @include themed() {
      border-color: set-theme(light-blue-color);
      background-color: set-theme(light-blue-color);
    }

    &:disabled {
      opacity: 0.7;
      cursor: no-drop;
    }

    &:focus {
      @include box-shadow(none);
      @include themed() {
        border-color: set-theme(primary-color);
      }
      outline: none;
      background-color: color(white);
    }

    &--icon {
      padding-left: 0;

      &-block {
        position: relative;
        width: 100%;
        padding-left: 4rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        @include themed() {
          background-color: set-theme(light-blue-color);
        }

        .form-control {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          &:focus {
            border-color: transparent;
          }
        }
      }
    }

    &::placeholder {
      color: rgba($color: color(dark), $alpha: 0.3);
    }
  }

  &__checkbox {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: transparent;

      @include themed() {
        border: 2px solid set-theme(secondary-color);
      }

      &::after {
        content: '';
        display: none;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;

        @include themed() {
          background: set-theme(secondary-color);
        }
      }

      &--radio {
        border-radius: 50%;

        &::after {
          border-radius: 50%;
        }
      }
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;

      &:checked {
        & ~ .checkmark {
          @include themed() {
            border-color: set-theme(secondary-color);
          }

          &::after {
            display: block;
          }
        }
      }
    }

    &:hover {
      input ~ .checkmark {
        @include themed() {
          background-color: set-theme(light-blue-color);
        }
      }
    }

    &-group {
      position: relative;
      margin-bottom: 1.6rem !important;

      &--nm {
        margin-bottom: 0 !important;
      }
    }
  }

  &__group {
    position: relative;
    margin-bottom: 4rem;
  }

  &__icon {
    @include font-size(regular);
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    left: 1.6rem;
    color: color(grey-shade1);
  }

  &__label {
    @include font-size(small);
    display: block;
    margin-bottom: 0.8rem;
    color: color(grey);
    line-height: normal;
  }

  &__select {
    .select {
      &__control {
        min-height: 50px;
        border-radius: 10px;

        @include themed() {
          border-color: set-theme(light-blue-color);
          background-color: set-theme(light-blue-color);
        }

        &:hover {
          @include themed() {
            border-color: set-theme(primary-color);
          }
        }
      }

      &__menu {
        @include themed() {
          border-color: set-theme(light-blue-color);
          background-color: set-theme(light-blue-color);
        }

        &-notice {
          @include font-size(small);

          &--no-options {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }
      }

      &__multi-value {
        @include themed() {
          background-color: set-theme(secondary-color);
        }

        &__label {
          @include font-size(x-small);
        }
      }

      &__option {
        @include font-size(small);

        &--is-focused {
          @include themed() {
            background-color: set-theme(secondary-color);
          }
        }

        &--is-selected {
          @include themed() {
            background-color: set-theme(primary-color);
          }
        }
      }

      &__placeholder {
        @include font-size(small);
        color: rgba($color: color(dark), $alpha: 0.3);

        @include respond(medium) {
          @include font-size(regular);
        }
      }

      &__single-value {
        @include font-size(small);

        @include respond(medium) {
          @include font-size(regular);
        }
      }

      &__value-container {
        padding: 0 1.6rem;
      }
    }
  }
}
