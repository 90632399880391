.card-container {
  background: color(light-grey);
  padding: 3rem;

  .card_heading {
    @include themed() {
      color: set-theme(primary-color);
    }
  }
}

.info-popup {
  background: color(green);
  color: color(white);
  padding: 0.8rem 1.6rem;
}

.visitor {
  .visitor_heading {
    color: color(blue);
    padding-right: 1.5rem;
    @include font-weight(regular);
    @include font-size(regular);
  }
}
