.wallet_chart {
  margin: 2rem auto;
  .dot {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    margin-right: 0.5rem;
  }
  .debit {
    background: #ef4747;
  }
  .credit {
    background: rgb(36 171 74);
  }
  .text__circle {
    display: flex;
    align-items: baseline;
  }
  .btn__style {
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .display_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .chart__datepicker {
    margin-bottom: 7rem;
  }

  .info_text {
    width: max-content;
    margin-left: 4rem;
  }
  .wallet_chart__select {
    margin-bottom: 0;
    border: 1px solid secondary;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__control {
      border-color: secondary;
      background-color: transparent;
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }

    .select__single-value {
      @include font-size(regular);
    }

    .select__value-container {
      padding-left: 0;
    }

    .select__menu {
      width: 250px;
      border: 1px solid secondary;
      border-radius: 10px;
      background-color: color(white);
      box-shadow: 0px 4px 60px secondary;
    }
  }
  .chart {
    @include font-size(regular);

    &__body {
      margin-top: 2rem;
      width: 100%;
      height: 400px;
    }

    &__datepicker {
      display: flex;
      align-items: center;
      // flex-wrap: wrap;

      button {
        margin-bottom: 1rem;
      }

      input {
        @include font-size(small);
        position: relative;
        width: 105px;
        padding: 0.6rem 0.9rem;
        border-color: rgba(0, 0, 0, 0.05);
        color: rgba($color: color(dark), $alpha: 0.7);
      }

      .react-datepicker-wrapper {
        &:first-child {
          input {
            border-right-width: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        &:nth-child(2) {
          input {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .react-datepicker__input-container {
        &::after {
          @include font-family(icomoon);
          content: '\e904';
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          font-size: 6px;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__select {
      margin-bottom: 0;
      border: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .select__indicator-separator {
        display: none;
      }

      .select__control {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }

      .select__single-value {
        @include font-size(large);
      }

      .select__value-container {
        padding-left: 0;
      }

      .select__menu {
        min-width: 200px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background-color: color(white);
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
