@mixin font-family($keyword) {
  font-family: get-font-family($keyword);
}

@mixin font-size($keyword) {
  font-size: get-font-size($keyword);
}

@mixin font-weight($keyword) {
  font-weight: get-font-weight($keyword);
}

@mixin respond($keyword) {
  // default will be small mobile phones

  @if get-break-point($keyword) {
    @media only screen and (min-width: get-break-point($keyword)) {
      @content;
    }
  }
}

@mixin transition($value) {
  -webkit-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin animation($value) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin transition-duration($time) {
  -webkit-transition-duration: $time;
  -o-transition-duration: $time;
  transition-duration: $time;
}

@mixin box-shadow($content) {
  -webkit-box-shadow: $content;
  box-shadow: $content;
}

@mixin context-menu {
  @include transform(translate3d(-100px, 30px, 0px));
  @include box-shadow(0px 1px 15px 5px #e6eaec);
  will-change: transform;
  width: max-content;
  animation-name: dropdownAnimation;
  animation-duration: 350ms;
  animation-fill-mode: both;
  background-color: color(white);

  &.show {
    display: block;
  }
}
