.property_type_header {
  .property__input {
    margin-right: 16px;
  }
  .property__btn {
    height: 50px !important;
  }
}

.property_modal {
  .property_create_modal_cancel {
    margin-left: 16px;
    background: color(white);

    @include themed() {
      border: solid 1px set-theme(secondary-color);
      color: set-theme(primary-color);
    }
  }
  .poll_icon {
    padding-top: 3rem;
    padding-left: 3rem;
    font-size: 3rem;
    cursor: pointer;
  }
}

.poll_card {
  .poll_card_btn {
    text-align: right;
  }
}

.table_row {
  cursor: pointer;
}
