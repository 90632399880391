#dashboard {
  .btn__style {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
  }

  .display_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chart {
    @include font-size(regular);

    &__body {
      width: 100%;
      height: 400px;
      margin-top: 2rem;
    }

    &__datepicker {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include respond(large) {
        flex-direction: row;
        align-items: center;
      }

      &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      button {
        margin-bottom: 1rem;
      }

      input {
        @include font-size(small);
        position: relative;
        width: 105px;
        padding: 0.6rem 0.9rem;
        border-color: rgba(0, 0, 0, 0.05);
        color: rgba($color: color(dark), $alpha: 0.7);
      }

      .react-datepicker-wrapper {
        &:first-child {
          input {
            border-right-width: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        &:nth-child(2) {
          input {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .react-datepicker__input-container {
        &::after {
          @include font-family(icomoon);
          content: '\e904';
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          font-size: 6px;
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;

      @include respond(large) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__select {
      margin-bottom: 0;
      border: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .select__indicator-separator {
        display: none;
      }

      .select__control {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }

      .select__single-value {
        @include font-size(medium);

        @include respond(maximum) {
          @include font-size(large);
        }
      }

      .select__value-container {
        padding-left: 0;
      }

      .select__menu {
        min-width: 200px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background-color: color(white);
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
