.filler {
  flex-grow: 1 !important;
}

@each $name, $weight in $font-weights {
  .font-#{'' + $name} {
    font-weight: $weight !important;
  }
}

@each $name, $size in $font-sizes {
  .font-size-#{'' + $name} {
    font-size: $size !important;
  }
}

@each $name, $color in $colors {
  .bg-#{'' + $name} {
    background: $color !important;
  }

  .color-#{'' + $name} {
    color: $color !important;
  }
}

.text-no-wrap {
  white-space: nowrap;
}
