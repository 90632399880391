.sun-editor {
  border-radius: 8px;
}
.sun-editor-editable,
.sun-editor .se-wrapper .se-wrapper-inner {
  background-color: #fafafa;
}
.sun-editor .se-resizing-bar {
  border-radius: 0 0 8px 8px;
}
.sun-editor .se-toolbar {
  background-color: #fff;
  border-radius: 8px 8px 0 0;
}
.sun-editor-editable,
.sun-editor .se-resizing-bar {
  background-color: #fff;
}
.sun-editor .se-btn-module-border {
  border: none;
}
.se-navigation {
  display: none;
}
