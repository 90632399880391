$colors: (
  black: #000,
  blue: #344cb7,
  dark: #141418,
  green: #49ec77,
  grey: #424950,
  grey-shade1: #a9abb6,
  light-blue: #e9ebfa,
  light-grey: #f8f9fa,
  red: #ef4747,
  white: #fff
);

$badge-colors: (
  black: #000,
  blue: #344cb7,
  dark: #141418,
  green: #49ec77,
  grey: #424950,
  red: #ef4747
);

$progess-colors: #000957, #3fd36d, #ebe645, #ee2ff6, #f62f6b, #1698e9;

$font-families: (
  raleway: 'Raleway',
  ubuntu: 'Ubuntu',
  circular: 'Circular Std',
  icomoon: 'icomoon'
);

$font-weights: (
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  black: 900
);

$font-sizes: (
  xx-small: 9px,
  x-small: 12px,
  small: 14px,
  regular: 16px,
  medium: 18px,
  large: 24px,
  m-large: 30px,
  x-large: 40px,
  xx-large: 48px
);

// mobile first
$break-points: (
  x-small: 300px,
  // x-small mobile phones
  smallest: 480px,
  // small tabs or phablets
  small: 600px,
  // tabs and ipads
  medium: 768px,
  // large tabs / large ipads / small laptops
  large: 990px,
  // laptops / desktops
  largest: 1200px,
  // large desktops
  maximum: 1400px,
  // very large monitors
  enormous: 1700px
);
