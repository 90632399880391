.section-advert {
  width: 100%;

  .carousel-item {
    text-align: center;
  }

  .advert__link {
    width: 100%;
    height: 100%;

    img {
      width: auto;
      height: 150px;
      object-fit: cover;
      object-position: top center;
    }
  }
}
