.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;

  @include themed() {
    background-color: lighten($color: set-theme(primary-color), $amount: 20%);
    // background-image: -webkit-gradient(
    //   linear,
    //   40% 0%,
    //   75% 84%,
    //   from(set-theme(primary-color)),
    //   to(lighten($color: set-theme(primary-color), $amount: 20%)),
    //   color-stop(0.6, set-theme(secondary-color))
    // );
  }
}

.scrollbar--table::-webkit-scrollbar {
  height: 10px !important;
}

.scrollbar--table::-webkit-scrollbar-thumb {
  background: color(grey-shade1) !important;
}
