.page {
  $login-primary-color: #000957;
  $login-secondary-color: #ebe645;

  &--admin {
    .container-fluid {
      max-width: 1400px;
    }
  }

  &--auth {
    .auth-grid {
      position: relative;
      width: 100%;
      height: 100vh;

      @include respond(large) {
        display: grid;
        position: fixed;
        top: 0;
        left: 0;
        grid-template-columns: 50% 50%;
      }

      @include respond(largest) {
        grid-template-columns: 650px calc(100% - 650px);
      }
    }

    .auth-col {
      position: relative;
      width: 100%;
      height: 100%;

      &--left {
        display: none;
        padding: 40px;
        overflow: hidden;
        background-color: $login-primary-color;

        @include respond(large) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        h3 {
          margin-bottom: 4rem;
          color: color(white);
          @include font-size(m-large);

          @include respond(largest) {
            font-size: 4.5rem;
          }
        }

        p {
          @include font-size(large);
          padding-bottom: 4rem;
          opacity: 0.83;
          color: color(white);

          @include respond(large) {
            @include font-size(medium);
          }
        }
      }

      &--right {
        // display: flex;
        width: 100%;
        height: 100%;
        padding: 9rem 4rem;
        overflow-y: auto;
        background-color: color(white);

        .auth__form {
          display: flex;
          position: relative;
          z-index: 2;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          max-width: 700px;
          height: auto;
          min-height: 100%;
          margin: 0 auto;

          .btn {
            border-color: $login-secondary-color !important;
            background-color: $login-secondary-color !important;

            &:hover {
              border-color: $login-primary-color !important;
              background-color: $login-primary-color !important;
            }
          }
        }

        .auth-icons {
          display: block;

          @include respond(large) {
            display: none;
          }
        }
      }
    }

    .auth-form {
      width: 95%;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .auth-icons {
      position: fixed;

      @include respond(large) {
        position: absolute;
      }

      &--1 {
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &--2 {
        z-index: 2;
        bottom: -250px;
        left: -187px;
        width: 351px;
        height: auto;
      }
    }
  }

  &-body {
    position: fixed;
    z-index: auto;
    left: 260px;
    width: 100%;
    height: 100vh;
    // padding-top: 100px;
    overflow-y: auto;
    transition-duration: 300ms;
    background-color: color(white);

    @include respond(large) {
      position: relative;
      left: 0;
      width: auto;
      height: auto;
      margin-left: 260px;
      overflow-y: unset;
    }
  }

  &.menu-collasped-active {
    .page-body {
      left: 0;
      margin-left: 0;

      @include respond(large) {
        margin-left: 55px;
      }
    }

    .header {
      left: 0;
      width: 100vw;

      @include respond(large) {
        left: 55px;
        width: calc(100% - 55px);
      }
    }

    .sidebar {
      @include respond(large) {
        left: -195px;
      }

      left: -280px;

      .sidebar__logo .small-logo {
        display: block;
        margin-right: 0;
      }

      .sidebar__logo .desktop-logo {
        display: none;
      }

      .logout_link {
        @include font-size(medium);
      }

      nav .sidebar__link {
        justify-content: center;
        width: 65px;
        margin-left: auto;
        padding-right: 0;
        padding-left: 0;

        .arrow {
          display: none;
        }
      }

      nav .sidebar__text {
        display: none;
      }

      nav .sidebar__icon {
        width: auto;
        text-align: center;
      }
    }

    &.sidemenu-hover-active {
      .header {
        left: 260px;
        width: calc(100% - 260px);
      }

      .logout_link {
        @include font-size(small);
      }

      .sidebar__logo--small {
        justify-content: center;
      }

      .sidebar__logo .small-logo {
        display: none;
      }

      .sidebar__logo .desktop-logo {
        display: block;
      }

      .sidebar {
        left: 0;
      }

      .sidebar__logo {
        width: 260px;
      }

      .sidebar nav .sidebar__link {
        justify-content: flex-start;
        width: auto;
        padding-right: 2.5rem;
        padding-left: 2.5rem;

        .arrow {
          display: inline-block;
        }
      }

      .sidebar nav .sidebar__text {
        display: block;
      }

      .sidebar nav .sidebar__icon {
        width: 3.6rem;
        margin-left: 0;
      }
    }
  }

  &-inner {
    width: 100%;
    height: calc(100vh - 145px);
    margin-top: 75px;
    padding: 2rem;
    overflow: auto;

    @include respond(large) {
      padding: 5rem 8rem;
    }
  }
}
