#invoice {
  .invoice {
    &__card-right {
      & > * {
        margin-top: -20px;
        background-color: inherit !important;
      }
    }
  }

  .payment-summary {
    width: 100%;
    border-radius: 5px;

    box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.01);
    text-align: center;

    @include themed() {
      background-color: set-theme(secondary-color);
    }
  }

  // .payment-order {
  //   &__number {
  //     padding-right: 1rem;
  //   }

  //   .payment-order__item {
  //     & > * {
  //       margin-left: 1rem;
  //       padding: 0.4rem 0.6rem;
  //       border-radius: 4px;
  //       color: white;
  //     }
  //   }
  // }

  .payment-order__item {
    & > * {
      margin-left: 1rem;
      padding: 0.3rem 0.4rem;
      border-radius: 4px;
      color: white;
    }
  }

  .paid-status {
    background-color: color(green);
  }

  .outstanding {
    margin-left: 1rem;
    padding: 0.3rem 0.4rem;
    border-radius: 4px;
    background-color: color(red);
    color: white;
    font-size: 11px;
  }
}

.invoice-item {
  margin: 2rem auto;
  border-radius: 5px;
  background: rgba(233, 235, 250, 0.5);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  color: rgba(20, 20, 24, 0.75);
  padding: 1.5rem 3rem;
}

.total {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  background: rgba(233, 235, 250, 0.3);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  color: #141418;
  font-size: 2rem;
}

.top-info {
  margin: 2rem auto;
  // border: 1px solid rgba(20, 20, 24, 0.1);
  border-radius: 5px;
  background: #e9ebfa;
  box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.01);
  padding: 1rem 0;
}
