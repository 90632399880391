.cart {
  right: 0;
  left: auto;
  opacity: 0;
  transform: translateX(100%);
  max-width: 550px;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.28, 0.47, 0.29, 0.86),
    opacity 0.4s ease-in-out 0.1s !important;

  &-body {
    flex: 1;
    padding-bottom: 1rem;
    overflow-y: auto;
  }

  .bill-card {
    &-wrapper {
      width: 100% !important;
      flex: 1 1 auto !important;
    }
  }

  &__close {
    @include font-size(xx-small);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: color(black);

    @include themed() {
      border: 1px solid set-theme(primary-color);
    }
  }

  &-footer {
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.15);
    border-top: 1px solid #d0d0d0;
    padding: 0 20px 20px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__list {
    padding: 20px;
  }

  .modal-content {
    border: 0;
    background: transparent;
    width: 100%;
    height: 100%;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    background-color: color(light-blue);
  }

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &__subtotal {
    @include font-size(regular);
    @include font-weight(semi-bold);

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 30px;
    padding-top: 1.5rem;

    @include respond(medium) {
      @include font-size(medium);
    }
  }

  &-top {
    margin-bottom: 10px;
    padding-top: 20px;
  }

  &__title {
    @include font-weight(regular);
    @include font-size(small);
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 1px;
    font-style: normal;
    color: color(black);
  }
}
