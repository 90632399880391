.table {
  @include font-size(small);
  width: 100%;

  @include respond(maximum) {
    @include font-size(regular);
  }

  // last column is sticky
  &.col-sticky {
    tr > th:last-child,
    tr > td:last-child {
      z-index: 1;
      position: sticky;
      right: 0;
    }
  }

  &-container {
    position: relative;
    width: 100%;
    overflow-x: auto;
  }

  thead {
    @include font-weight(medium);
    @include themed() {
      color: set-theme(primary-color);
    }
  }

  th,
  td {
    padding: 2.4rem 1rem;
    border-bottom: 5px solid color(white);
    background-color: #fafafa;

    p,
    li,
    a {
      @include font-size(small);

      @include respond(maximum) {
        @include font-size(regular);
      }
    }
  }

  td {
    &:not(:last-child) {
      min-width: 100px;
    }
  }

  tbody {
    & > tr:hover > * {
      background-color: color(light-blue);
    }
  }

  ul {
    li {
      padding: 5px 0;
      border-bottom: 1px solid color(light-blue);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.mobile-table {
  background-color: #fcfcfc;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 13px;
  box-shadow: 0px 4px 60px rgb(0 0 0 / 5%);
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 13px;
  }

  .price {
    font-size: 16px;
    font-weight: bold;
  }
}
