#emergency {
  .emergency__button {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 3rem;
    padding: 1rem 3rem;
    transition: all ease 0.3s;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: left;

    @include themed() {
      border-color: rgba($color: set-theme(secondary-color), $alpha: 0.2);
      background-color: rgba($color: set-theme(secondary-color), $alpha: 0.2);
    }

    h4 {
      @include font-size(medium);
    }

    &:hover {
      @include themed() {
        border-color: rgba($color: set-theme(primary-color), $alpha: 0.2);
        background-color: rgba($color: set-theme(primary-color), $alpha: 0.2);
      }
    }
  }
}

#emergency__contacts {
  .call__row {
    display: grid;
    grid-template-columns: 70px calc(100% - 70px);
    margin-bottom: 1.5rem;
    border-radius: 5px;
    @include themed() {
      border: 1px solid rgba($color: set-theme(secondary-color), $alpha: 0.3);
    }

    a:hover {
      text-decoration: none !important;
    }
  }

  .call__button {
    @include font-size(regular);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    padding: 1rem;
    transition: all ease 0.3s;
    border: 0;
    border-radius: 5px 0 0 5px;

    @include themed() {
      background-color: rgba($color: set-theme(secondary-color), $alpha: 0.2);
    }

    &:hover {
      @include themed() {
        background-color: rgba($color: set-theme(primary-color), $alpha: 0.2);
      }
    }

    &-block {
      display: flex;
      padding-bottom: 1rem;
      padding-left: 1rem;

      @include respond(small) {
        padding-bottom: 0;
        padding-left: 0;
      }

      button {
        width: auto;
        margin: 0 1rem;
        border-radius: 5px;
        background-color: transparent !important;

        @include respond(small) {
          width: 7rem;
          margin: 0;
        }

        &:first-child {
          background-color: color(light-blue) !important;

          @include respond(small) {
            background-color: transparent !important;
          }
        }

        &:last-child {
          background-color: rgba($color: color(red), $alpha: 0.2) !important;

          @include respond(small) {
            background-color: transparent !important;
          }
        }

        &:hover {
          @include themed() {
            background-color: rgba(
              $color: set-theme(secondary-color),
              $alpha: 0.2
            ) !important;
          }
        }
      }
    }
  }

  .call__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    @include respond(small) {
      flex-direction: row;
    }
  }

  .call__phone-block {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 1.5rem;
    padding: 1rem 2rem;

    @include respond(small) {
      padding-right: 3rem;
      padding-left: 3rem;
    }

    p {
      @include font-size(small);

      @include respond(small) {
        @include font-size(regular);
      }
    }

    h4 {
      @include font-size(regular);

      @include respond(small) {
        @include font-size(medium);
      }
    }
  }
}
