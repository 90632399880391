#resident__bills {
  .bills__footer {
    width: 100%;
    margin: 3rem 0;
    text-align: center;

    @include respond(small) {
      padding-top: 3rem;
      text-align: right;
    }

    button {
      &:last-child {
        margin-top: 1.5rem;

        @include respond(small) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }
    }
  }
}
