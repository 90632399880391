.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  padding: 1rem;
  background: color(light-grey);

  .copyright {
    @include font-size(small);
    margin-bottom: 0;
  }
}
