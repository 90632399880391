.header {
  display: flex;
  position: fixed;
  z-index: 98;
  top: 0;
  left: 260px;
  align-items: center;
  width: 100vw;
  height: 75px;
  transition-duration: 300ms;
  background-color: color(white);
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);

  @include respond(large) {
    width: calc(100% - 260px);
    padding-left: 15px;
  }

  &-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 0.5rem 2rem;

    @include respond(large) {
      padding-right: 3.5rem;
      padding-left: 3.5rem;
    }
  }

  .navbar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    background: color(white) !important;

    &-collapse {
      display: flex;
      flex-basis: auto;
    }

    &-nav {
      flex-direction: row;
    }

    &-toggler {
      padding: 0;
      border: 0;
      color: color(dark);
      font-size: 3rem;
    }
  }

  .nav-link {
    color: color(dark) !important;

    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 1.6rem;
    }

    &--icon {
      @include font-size(m-large);
      display: inline-flex;
      align-items: center;
      transition: color 0.3s ease;
      cursor: pointer;

      &:hover {
        @include themed() {
          color: set-theme(primary-color) !important;
        }
      }

      &-active {
        span {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 1px;
            right: 1px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: color(red);
          }
        }
      }
    }

    &--profile {
      display: flex;
      align-items: center;

      .profile__avatar {
        width: 32px;
        height: 32px;
        object-fit: cover;
        object-position: center;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: color(grey);
      }

      .profile__text {
        display: block;
        margin-bottom: 0;
        line-height: 1.3;

        &--light {
          @include font-weight(light);
          color: rgba(20, 20, 24, 0.5);
        }
      }
    }
  }
}
