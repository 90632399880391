.loader {
  display: flex;
  position: relative;
  z-index: 97;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 200px;

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &--fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    place-items: center;
  }

  .child-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .spin {
    position: relative;
    z-index: 3;

    &::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      margin: auto;
      transform: translate3d(-50%, -50%, 0);
      animation: 1.5s linear infinite spinner;
      animation-play-state: inherit;
      border: solid 10px #cfd0d1;
      border-radius: 50%;
      will-change: transform;

      @include themed() {
        border-top-color: set-theme(secondary-color);
        border-bottom-color: set-theme(primary-color);
      }
    }
  }
}
