#no_access.no-access {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .no-access {
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 500px;
    }
  }
}
