.btn {
  @include font-size(small);
  @include transition(color 0.4s);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  padding: 1.5rem 2.2rem;
  transition-property: color, background-color, border-color;
  border-radius: 10px;
  line-height: 1.5;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;

  &:active,
  &:focus {
    @include box-shadow(none);
    outline: 0;
  }

  &:hover {
    text-decoration: none !important;
  }

  &--outline,
  &--outline:active,
  &--outline:focus {
    background-color: transparent !important;

    @include themed() {
      border: 1px solid set-theme(secondary-color) !important;
      color: set-theme(primary-color) !important;
    }

    &:hover {
      @include themed() {
        background-color: set-theme(secondary-color) !important;
      }
    }
  }

  &--sm {
    @include font-size(x-small);
    padding: 0.6rem 1.5rem;

    &.btn--loader {
      width: 44px !important;
      height: 30px !important;
    }
  }

  &--lg {
    min-width: 150px;
  }

  &--xl {
    @include font-size(regular);
    min-width: 150px;

    &.btn--loader {
      width: 100px !important;
      height: 54px !important;
    }
  }

  &--fw {
    @include font-size(regular);
    min-width: 150px;

    &.btn--loader {
      width: 100% !important;
      height: 54px !important;
    }
  }

  &-primary,
  &-primary:active,
  &-primary:focus {
    color: color(white);

    @include themed() {
      border: 1px solid set-theme(primary-color);
      background-color: set-theme(primary-color);
    }

    @include respond(medium) {
      &:hover {
        color: color(white);

        @include themed() {
          border: 1px solid
            darken($color: set-theme(primary-color), $amount: 20);
          background: darken($color: set-theme(primary-color), $amount: 20);
        }
      }
    }

    &.btn--loader::before {
      @include themed() {
        border: solid 5px rgba($color: set-theme(secondary-color), $alpha: 0.8) !important;
        border-top-color: color(white) !important;
      }
    }
  }

  &-link {
    &-primary,
    &-primary:active,
    &-primary:focus {
      @include themed() {
        border-color: transparent;
        background-color: transparent;
        color: set-theme(primary-color);
      }

      @include respond(medium) {
        &:hover {
          @include themed() {
            border: 1px solid
              darken($color: set-theme(primary-color), $amount: 20);
            background-color: transparent;
            color: set-theme(primary-color);
          }
        }
      }
    }
  }

  &-secondary,
  &-secondary:active,
  &-secondary:focus {
    color: color(black);

    @include themed() {
      border: 1px solid set-theme(secondary-color);
      background-color: set-theme(secondary-color);
    }

    @include respond(medium) {
      &:hover {
        color: color(white);

        @include themed() {
          border: 1px solid set-theme(primary-color);
          background: set-theme(primary-color);
        }
      }
    }

    &.btn--loader::before {
      @include themed() {
        border: solid 5px rgba($color: set-theme(primary-color), $alpha: 0.8) !important;
        border-top-color: color(white) !important;
      }
    }
  }

  &--loader {
    position: relative;
    z-index: 3;
    width: 50px;
    height: 51px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin: auto;
      transform: translate3d(-50%, -50%, 0);
      animation: 1.5s linear infinite spinner;
      animation-play-state: inherit;
      border: solid 5px rgba($color: color(grey-shade1), $alpha: 0.8);
      border-radius: 50%;
      border-top-color: color(grey);
      background-color: transparent;
      will-change: transform;
    }
  }
}

@each $name, $color in $colors {
  .btn-#{'' + $name},
  .btn-#{'' + $name}:active,
  .btn-#{'' + $name}:focus {
    @include themed() {
      @if $color !=
        color(white) and
        $color !=
        set-theme(secondary-color) and
        $color !=
        set-theme(primary-color)
      {
        border: 1px solid $color;
        background-color: $color;
        color: color(white);

        @include respond(medium) {
          &:hover {
            border: 1px solid darken($color: $color, $amount: 20);
            background: darken($color: $color, $amount: 20);
            color: color(white);
          }
        }
      }
    }
  }

  .btn-link-#{'' + $name},
  .btn-link-#{'' + $name}:active,
  .btn-link-#{'' + $name}:focus {
    @include themed() {
      border-color: transparent;
      background-color: transparent;
      color: $color;

      @include respond(medium) {
        &:hover {
          border: 1px solid darken($color: $color, $amount: 20);
          background-color: transparent;
          color: $color;
        }
      }
    }
  }
}
