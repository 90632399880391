#faq {
  .accordion-button {
    background-color: #e9ebfa !important;
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: 450;
    font-size: 18.6064px;
    line-height: 24px;
    padding: 2rem;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    color: #141418;
    background-color: #ebe645 !important;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .accordion-item {
    border: none;
    background: #ebe645;
  }

  .accordion-body {
    background-color: #ebe645 !important;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 300;
    font-size: 18.6064px;
    line-height: 26px;
    color: #ffffff;

    .Accordion-body-child {
      background: rgba(0, 9, 87, 0.3);
      border: 0.876388px solid rgba(28, 31, 49, 0.1);
      border-radius: 13.1458px;
      padding: 2rem;
    }
  }
}
