.chart__datepicker {
  display: flex;
  align-items: center;
}

button {
  margin-bottom: 1rem;
}

.form__control--icon-block {
  input {
    border-color: transparent;
  }
}

input {
  @include font-size(small);
  position: relative;
  padding: 0.6rem 0.9rem;
  border-color: rgba(0, 0, 0, 0.05);
  color: rgba($color: color(dark), $alpha: 0.7);
}
.react-datepicker-wrapper {
  &:first-child {
    input {
      border-right-width: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &:nth-child(2) {
    input {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.filter-menu .react-datepicker__input-container {
  input {
    width: 120px;
  }

  &::after {
    @include font-family(icomoon);
    content: '\e904';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font-size: 6px;
  }
}
