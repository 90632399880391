.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 0;

  &__icon {
    display: inline-block;
    margin-bottom: 1.5rem;
    font-size: 5rem;
  }

  &__image {
    display: inline-block;
    object-fit: cover;
    object-position: center;
  }

  &__inner {
    display: inline-block;
    width: auto;
    text-align: center;
  }

  p {
    @include font-size(regular);
  }
}
