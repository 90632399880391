.modal {
  &-body {
    padding: 4rem;
  }

  .btn-close {
    position: absolute;
    top: 5rem;
    right: 4.8rem;
    margin: 0;
    opacity: 1;

    @include themed() {
      color: set-theme(primary-color);
    }

    &:hover {
      color: color(red);
    }
  }

  &-content {
    border: 0;
    border-radius: 0;
  }

  &-header {
    position: relative;
    justify-content: center;
    padding: 0 38px;
    //justify-content: center;
    border: 0;
  }

  &-title {
    @include font-size(large);
    padding-top: 3rem;
    text-align: center;

    @include themed() {
      color: set-theme(primary-color);
    }
  }
}
