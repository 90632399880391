$themes: (
  default: (
    // colors #000957 #ebe645
    primary-color: #000957,
    secondary-color: #ebe645,
    light-blue-color: #e9ebfa,
    // font families
    heading-font-family: get-font-family(circular),
    body-font-family: get-font-family(circular),
    sub-heading-font-family: get-font-family(circular),
    light-font-family: get-font-family(circular),
    // font weights
    heading-font-weight: get-font-weight(bold),
    sub-heading-font-weight: get-font-weight(medium)
  ),
  resident: (
    // colors
    primary-color: #313ca3,
    secondary-color: #eeeb8c,
    light-blue-color: #e9ebfa,
    // font families
    heading-font-family: get-font-family(raleway),
    body-font-family: get-font-family(ubuntu),
    sub-heading-font-family: get-font-family(ubuntu),
    light-font-family: get-font-family(ubuntu),
    // font weights
    heading-font-weight: get-font-weight(bold),
    sub-heading-font-weight: get-font-weight(bold)
  )
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

// theme colors
@function set-theme($key) {
  @return map-get($theme-map, $key);
}
