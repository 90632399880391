.nav {
  &-item {
    margin: 0 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &-link {
    &.active {
      @include themed() {
        color: set-theme(primary-color) !important;
      }
    }
  }

  &bar {
    &-toggler {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 1rem;
  transform: translateY(-20%);
  font-size: 6px;
}
