.file-upload {
  width: 40rem;

  &__button {
    @include font-size(medium);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    height: calc(100% - 1.6rem);
    border: 0;
    border-radius: 5px;
    color: color(white);

    @include themed() {
      border-color: set-theme(primary-color);
      background-color: set-theme(primary-color);
    }
  }

  &__feedback {
    @include font-size(regular);
    flex: 1;
    display: inline-block;
    padding: 0 1.6rem;
    line-height: normal;
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;
    height: 50px;

    @include themed() {
      border-color: set-theme(light-blue-color);
      background-color: set-theme(light-blue-color);
    }

    & > * {
      vertical-align: middle;
    }
  }

  &__input {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid rgba($color: color(grey-shade1), $alpha: 0.3);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-right: 2rem;
    }

    &-icon {
      margin-right: 1.5rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
