.section {
  width: 100%;
  padding: 3rem 0;

  @include respond(large) {
    padding: 6rem 0;
  }

  &-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    .form__control {
      @include font-size(small);

      @include respond(maximum) {
        @include font-size(regular);
      }
    }

    h3 {
      white-space: nowrap;
    }

    .btn {
      padding: 1rem 2.2rem;

      @include respond(small) {
        padding: 1.5rem 2.2rem;
      }
    }

    .subtitle {
      @include font-size(medium);
      color: color(grey);
    }

    &--block {
      display: block;

      @include respond(medium) {
        display: flex;
      }

      h3 {
        margin-bottom: 3rem !important;

        @include respond(medium) {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
