.outline_payment {
  .outstanding_payment_btn {
    height: 50px;
    margin-left: 16px;
  }
  .outstanding_payment_btn_diff {
    background: #49ec77;
    border: none;
    margin-left: 16px;
    height: 50px;
  }
}

.outstanding_table_style {
  min-width: 750px;
  overflow-x: auto;
}

.property_modal {
  .property_create_modal_cancel {
    margin-left: 16px;
    background: color(white);

    @include themed() {
      border: solid 1px set-theme(secondary-color);
      color: set-theme(primary-color);
    }
  }
}
