#resident__checkout.checkout {
  .banner {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin: 0 auto 4rem;
    padding: 3.6rem 1rem;
    border-radius: 5px;

    @include themed() {
      background-color: rgba($color: set-theme(secondary-color), $alpha: 0.3);
    }

    &__amount {
      @include font-size(large);
      margin-bottom: 0;

      @include respond(medium) {
        font-size: 40px;
      }
    }

    &__title {
      @include font-size(regular);
      @include font-weight(bold);

      @include respond(medium) {
        @include font-size(medium);
      }
    }
  }

  .checkout {
    &__col {
      display: block;

      &--left {
        flex: 1 1 auto;

        p {
          @include font-size(regular);

          @include respond(medium) {
            @include font-size(medium);
          }

          &:last-child {
            @include font-size(small);

            @include respond(medium) {
              @include font-size(regular);
            }
          }
        }
      }

      &--right {
        text-align: right;
        min-width: fit-content;

        p {
          @include font-size(regular);

          @include respond(medium) {
            @include font-size(medium);
          }
        }
      }
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      margin: 1rem 0;
      padding: 1rem 2.5rem;

      @include respond(medium) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
  }

  .vat {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1.5rem 2.5rem;
      border-bottom: 2px solid #ebf4fc;

      p {
        @include font-size(regular);
        @include font-weight(bold);
        margin-bottom: 0;

        @include respond(medium) {
          @include font-size(medium);
        }
      }
    }
  }
}
